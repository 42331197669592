.App {
  font-family: Open Sans;
}

.CategoryButton {
  display: flex;
  align-items: center;
  padding: 0.2em 2em;
  border-radius: 1em;
  cursor: pointer;
  /* background-color: red; */
}

@media (max-width: 900px) {
  .CategoryPicker {
    flex-direction: column;
    height: 9em !important;
  }
}

.CategoryButton:hover {
  background-color: #f1fcab;
}

.CategoryButtonSelected {
  background-color: #f1fcab;
}

.ConnectWalletSection {
  background-color: #ffffee;
  margin-top: 2em;
  height: 6em;
  width: 50%;
  border: 1px solid #cdcdcd;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ConnectWalletSection:hover {
  width: 55%;
}

.StakingSection {
  background-color: #ffffee;
  margin-top: 2em;
  padding: 1em 2%;
  /* height: 6em; */
  width: 46%;
  border: 1px solid #cdcdcd;
  border-radius: 0.5em;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* cursor: pointer; */
}

.MainSubText {
  font-size: 16px;
  margin-top: 1em;
  color: #154ca3;
}

.MainSubText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.AnnouncementBarText {
  font-size: 16px;
  margin: 0em;
  color: #646464;
  text-align: center;
  cursor: pointer;
}

.AnnouncementBarText:hover {
  text-decoration: underline;
}
